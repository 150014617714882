// App.js
import { HashRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./index.css";
import HomePage from "./pages/HomePage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ebe4d2 ",
    },
    secondary: {
      main: "#357DED",
    },
    terceary: {
      main: "#357DED",
    },
    mp: {
      main: "#00A3E0 ",
    },
    white: { main: "#fff" },
    backg: { main: "#F4FEC1" },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1880,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
